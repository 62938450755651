import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBOTnRbfF4KURynZOkaJ0NWDXPBGmOiFHk",
  authDomain: "filesharing-d7b75.firebaseapp.com",
  databaseURL: "https://filesharing-d7b75.firebaseio.com",
  projectId: "filesharing-d7b75",
  storageBucket: "filesharing-d7b75.appspot.com",
  messagingSenderId: "471056995433",
  appId: "1:471056995433:web:42e7d186b4b5cbb76dbef8",
  measurementId: "G-C3QH7LB9KK",
};

const app = firebase.initializeApp(firebaseConfig);
