import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Modal,
  Container,
  Row,
  Col,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Navbar,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./ShowServiceClass.css";
import check from "../assets/icons/svg/check.png";
import axios from "axios";
import BannerImage from "./BannerImage";

export default function ShowServiceClass(props) {
  const [serviceName, setServiceName] = useState("");
  const [serviceID, setServiceID] = useState("");
  const [serviceSelected, setServiceSelected] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [userCanceledService, setUserCanceledService] = useState(false);
  const [waitSucess, setWaitSucess] = useState(false);
  const [monthly, setMonthly] = useState(true);
  const [cookies, setCookie] = useCookies(["name"]);
  const [paidPlans, setPaidPlans] = useState({});
  const [isSubscribed, setIssubscribed] = useState(false);
  const navigate = useNavigate();
  const firebaseAddress = process.env.REACT_APP_SERVER_URL;
  const [show, setShow] = useState(false);
  const [currentSubID, setCurrentSubID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const setUserService = async (Id) => {
    if (isSubscribed) {
      await updateSubscription(Id);
      setTimeout(() => {
        navigate(`/service-usage-info`);
      }, 1000);
    } else {
      setTimeout(() => {
        navigate(`/create-subscription/?class_id=${Id}`);
      }, 1000);
    }
  };

  const updateSubscription = async (Id) => {
    const subscription = {
      user_id: props.user.id,
      product_id: Id,
    };
    await axios
      .post(`${firebaseAddress}updateSubscription`, {
        subscription,
      })
      .then((res) => {
        setSuccess("Successfully Switched Service");
        // These line wait till 2 mins to allow next subscription
        var date = new Date();
        date.setTime(date.getTime() + 60 * 2000);
        setCookie("name", "newName", { expires: date, path: "/" });
        setWaitSucess(true);
      })
      .catch((err) => {
        setError("Failed to Switch Service, Please try after some time!");
      });
  };

  const updateBillingPeriod = (period) => {
    setMonthly(!monthly);
  };

  const updateSelectedProduct = (id, name, isSelected = true) => {
    setServiceID(id);
    setServiceSelected(isSelected);
    setServiceName(name);
  };

  const getproductList = () => {
    // Post to server retrieve questions from database
    axios
      .get(`${firebaseAddress}getproductList`, {})
      .then((res) => {
        setPaidPlans(res.data.data);
      })
      .catch((err) => {});
  };

  const checkSubscribed = () => {
    // Post to server retrieve questions from database
    axios
      .post(`${firebaseAddress}getCurrentProductInfo`, {
        userId: props.user.id,
      })
      .then((res) => {
        if (res.data.data.Price !== "N/A") {
          setIssubscribed(true);
        } else {
          setIssubscribed(false);
        }
      })
      .catch((err) => {
        setIssubscribed(false);
        // usually if 500 server error set is subscribed goes to false
      });
  };

  const initCurrentSub = () => {
    // Post to server retrieve questions from database
    axios
      .post(`${firebaseAddress}getCurrentProductInfo`, {
        userId: props.user.id,
      })
      .then((res) => {
        setCurrentSubID(res.data.data.ProductId);
      })
      .catch((err) => {
        setCurrentSubID("");
        // usually if 500 server error set is subscribed goes to false
      });
  };

  useEffect(() => {
    getproductList();
    initCurrentSub();
  }, []);

  useEffect(() => {
    if (props.user) {
      checkSubscribed();
    }
  }, [props]);

  function generateProducts() {
    const displayPlans = Object.entries(paidPlans).filter(
      ([key, value]) => value.billing_period === "month" && monthly
    );
    // filter by monthly or annual
    return displayPlans.map(([key, value]) => (
      <Col sm={12} md={6} className={"mb-2"} key={key}>
        <Card
          style={{
            boxShadow:
              key === serviceID ? "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" : "",
          }}
        >
          <Card.Body className="pt-4 pb-0">
            <Card.Subtitle className="text-uppercase small text-center">
              <b>{value.product_name}</b>
            </Card.Subtitle>
            <Card.Title className="text-center mt-1">
              {value.price} {value.currency} per {value.billing_period}
            </Card.Title>
            {key === currentSubID ? (
              <Card.Subtitle
                className="small text-center"
                style={{ color: "darkgreen" }}
              >
                <b>(Current Subscription)</b>
              </Card.Subtitle>
            ) : null}
          </Card.Body>
          <hr />
          <Card.Body>
            {/* Plan Options */}
            <Stack className="align-items-start mb-4" gap={3}>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Number of Files that can be shared:{" "}
                <b>{value.attributes.file_limit}</b>
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Maximum number of encryptions:{" "}
                <b>{value.attributes.file_max_amount}</b>
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                SMS
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Email
              </span>
            </Stack>
            {/* Selection Button */}
            <Button
              style={{ width: "100%" }}
              active={key === serviceID}
              disabled={key === currentSubID}
              onClick={() => {
                if (key === serviceID) {
                  // allow user to deselect a plan
                  updateSelectedProduct("", "", false);
                } else {
                  updateSelectedProduct(key, value.product_name);
                }
              }}
            >
              {key === serviceID ? "Deselect" : "Select"}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  return (
    <>
      <Card
        body
        className="pb-0"
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <BannerImage />
        <h4 className="mt-3">
          <b>Choose the Best Plan for You</b>
        </h4>
        <hr className="mt-0" />
        <div className="d-flex justify-content-center mb-3">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={monthly ? 1 : 2}
          >
            <ToggleButton
              onClick={updateBillingPeriod}
              value={1}
              disabled={monthly}
            >
              Monthly Billing
            </ToggleButton>
            <ToggleButton
              onClick={updateBillingPeriod}
              value={2}
              disabled={!monthly}
            >
              Annual Billing
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Row>{generateProducts()}</Row>
        <Navbar sticky="bottom">
          <Container
            className="p-3 mb-2 bg-white rounded"
            style={{
              boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.25)",
            }}
          >
            {serviceSelected ? (
              <>
                <Navbar.Brand>{paidPlans[serviceID].product_name}</Navbar.Brand>
                <Navbar.Text className="me-auto">
                  {paidPlans[serviceID].price} {paidPlans[serviceID].currency}{" "}
                  per {paidPlans[serviceID].billing_period}
                </Navbar.Text>
              </>
            ) : (
              <Navbar.Brand>No Service Selected</Navbar.Brand>
            )}
            <Stack direction="horizontal">
              <Button
                className="ms-auto me-2"
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              <Button disabled={!serviceSelected} onClick={handleShow}>
                Purchase Subscription Tier
              </Button>
            </Stack>
          </Container>
        </Navbar>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* I have added cookies too see what would happen if there already exist */}
          {!isSubscribed && cookies.name !== "newName" && (
            <div>
              If you want to upgrade your subscription tier, please click the
              pay now button to continue. After payment, your current
              subscription tier will be upgraded to
              <strong>{" " + serviceName}</strong> immediately.
            </div>
          )}
          {isSubscribed && cookies.name !== "newName" && (
            <div>
              If you want to switch your subscription tier, please click the
              confirm button to continue. After confirmation, your current
              subscription tier will be switched to
              <strong>{" " + serviceName}</strong> at the beginning of next
              month.
            </div>
          )}
          {cookies.name !== "newName" && userCanceledService && (
            <div>
              <strong>Notice:</strong> Since your subscription for next month
              was canceled previously, if you confirm to select this class of
              service, your subscription will be resumed.
            </div>
          )}
          {cookies.name === "newName" && waitSucess !== true && (
            <div>
              You have recently changed your subscription status. Please try
              after one hour.
            </div>
          )}
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          {/* Ternary operetator to see if a user has changed subscription recently  */}

          {cookies.name === "newName" ? (
            <Button variant="primary" onClick={handleClose}>
              Go back
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setUserService(serviceID);
                }}
              >
                {isSubscribed ? "Confirm" : "Pay now"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
