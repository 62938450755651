import React, { Fragment, useState, useEffect } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Button, Row, Col, Form, Modal, Collapse } from "react-bootstrap";
import axios from "axios";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import background from "../../assets/images/background-black.png";
import {
  generateVerification,
  getNewEncryptedPass,
} from "../../functions/encoding";
import { formCheck, XOR, hashString } from "../../functions/encoding";
import { useNavigate } from "react-router-dom";
import FeedbackAlert from "../FeedbackAlert";
import "./ChangePassword.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../LoadingButton";
import PasswordWrapper from "../PasswordWrapper";
library.add(faEye, faEyeSlash);


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ChangePassword(props) {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    answers: Array(5).fill(""),
    oldPassword: "",
    newPassword: "",
    code: "",
  });
  const MIN_PW_LENGTH = 8;
  const [page, setPage] = useState(false);
  const [questions, setQuestions] = useState(Array(5).fill(""));
  const [verifySQFeedback, setVerifySQFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordNShown, setPasswordNShown] = useState(false);
  const [pwdFormFeedback, setPwdFormFeedback] = useState(null);
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const togglePasswordN = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordNShown(!passwordNShown);
  };
  const [passwordCShown, setPasswordCShown] = useState(false);
  const togglePasswordC = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordCShown(!passwordCShown);
  };

  const onChangeAnswer = (index, value) => {
    let newAnswer = formValue.answers;
    newAnswer[index] = value;
    setFormValue({ ...formValue, answers: newAnswer });
  };

  const handleQuestionsSubmit = (e) => {
    e.preventDefault();
    let oldCheck = formCheck(formValue.oldPassword + props.user.id);
    let newCheck = formCheck(formValue.newPassword + props.user.id);
    let verification = generateVerification(formValue.answers.join(""));
    let oldHashed = hashString(formValue.oldPassword),
      newHashed = hashString(formValue.newPassword);
    let passTransform = XOR(oldHashed, newHashed);
    let newEncryptedPass = getNewEncryptedPass(
      questions.join(""),
      formValue.answers.join(""),
      formValue.newPassword
    );
    setIsLoading(true);
    axios
      .post(SERVER_URL + "changePassword", {
        oldCheck: oldCheck,
        newCheck: newCheck,
        verification: verification,
        passTransform: passTransform,
        newEncryptedPass: newEncryptedPass,
        userId: props.user.id,
      })
      .then((res) => {
        if (res.data.success) {
          setVerifySQFeedback({
            loading: true,
            variant: "success",
            message: `Password has been changed! Redirecting...`,
          });
          setTimeout(() => {
            navigate("/new");
          }, 1500);
        } else {
          alert("Oops, something went wrong...");
        }
      })
      .catch((error) => {
        if (
          error.response.data.message === "Error: Question verification failed"
        ) {
          setVerifySQFeedback({
            variant: "warning",
            message: `Please Recheck your questions`,
          });
        } else {
          setVerifySQFeedback({
            variant: "warning",
            message: `Something went wrong with security question verification.`,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateAccountPwd = (pwd, confirm) => {
    setPwdFormFeedback(null);
    if (pwd !== confirm) {
      setPwdFormFeedback({
        message: `Passwords do not match.`,
        variant: "danger",
      });
    }
    var regExp = /[a-zA-Z]/g;
    var hasNumber = /\d/;
    if (pwd === "") {
      setPwdFormFeedback({
        message: "Please fill in your password.",
        variant: "danger",
      });
    } else if (
      pwd.length < MIN_PW_LENGTH ||
      !regExp.test(pwd) ||
      !hasNumber.test(pwd)
    ) {
      setPwdFormFeedback({
        message: `Please have a password with at least ${MIN_PW_LENGTH} characters, one letter and one number.`,
        variant: "warning",
      });
    } else {
      setFormValue({ ...formValue, newPassword: pwd });
    }
  };

  const verifyBody = (
    <Fragment>
      <PasswordWrapper
        requiresOTP
        user={props.user}
        setPassword={(oldPassword) => {
          setFormValue({
            ...formValue,
            oldPassword,
          });
        }}
      >
        <Form
          className="align-items-center"
          style={{ width: "100%", display: "contents" }}
          onSubmit={(e) => {
            setPage(true);
          }}
        >
          <Form.Group className="mb-3 row">
            <Form.Label column className="text-center text-lg-end px-0">
              New Password
            </Form.Label>
            <Col xs={12} lg={10}>
              <div className="pass-wrapper mb-0">
                <input
                  className="form-control"
                  name="newPassword"
                  type={passwordNShown ? "text" : "password"}
                  placeholder="Password"
                  value={pwd}
                  required="required"
                  onChange={(e) => {
                    validateAccountPwd(e.target.value, confirmPwd);
                    setPwd(e.target.value);
                  }}
                />
                <i className="test">
                  <FontAwesomeIcon
                    icon={passwordNShown ? "eye-slash" : "eye"}
                    onClick={togglePasswordN}
                  />
                </i>
              </div>
            </Col>
          </Form.Group>
          <Form.Group className="my-3 row mb-0" controlId="reEnter">
            <Form.Label column className="text-center text-lg-end px-0">
              Confirm Password
            </Form.Label>
            <Col xs={12} lg={10}>
              <div className="pass-wrapper mb-0">
                <input
                  className="form-control"
                  type={passwordCShown ? "text" : "password"}
                  name="reEnter"
                  onChange={(e) => {
                    validateAccountPwd(pwd, e.target.value);
                    setConfirmPwd(e.target.value);
                  }}
                />
                <i className="test">
                  <FontAwesomeIcon
                    icon={passwordCShown ? "eye-slash" : "eye"}
                    onClick={togglePasswordC}
                  />
                </i>
              </div>
            </Col>
          </Form.Group>
          <LoadingButton
            loading={isLoading}
            disabled={
              !(pwd === confirmPwd) || isLoading || pwdFormFeedback !== null
            }
            type="submit"
            className="w-100 mt-3"
          >
            Next
          </LoadingButton>
          <FeedbackAlert feedback={pwdFormFeedback} className="mt-2 mb-0" />
        </Form>
      </PasswordWrapper>
    </Fragment>
  );

  const changeBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={handleQuestionsSubmit}
      >
        <SecurityQuestionCheck
          answers={formValue.answers}
          onChangeAnswer={onChangeAnswer}
          user={props.user}
          setQuestions={setQuestions}
        />
        <LoadingButton
          loading={isLoading}
          disabled={
            !(pwd === confirmPwd) ||
            isLoading ||
            (verifySQFeedback && verifySQFeedback.loading)
          }
          type="submit"
          className="mt-3 w-100"
        >
          Submit
        </LoadingButton>
        <FeedbackAlert feedback={verifySQFeedback} className="mt-3 mb-0" />
      </Form>
    </Fragment>
  );

  return (
    <Fragment>
      <ManageAccountOptsPage
        title={page ? "Please provide answers" : "Change Password"}
        background={background}
        body={page ? changeBody : verifyBody}
      />
    </Fragment>
  );
}
