import axios from "axios";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import InfoButton from "./InfoButton.js";
import FileUploadBox from "../components/FileUploadBox";
import { verifyPassword } from "../util/SecureCommunication";
import CryptoJS from "crypto-js";
import "./new.css";
import ComponentCard from "./ComponentCard.js";
import {
  bytesToString,
  convertWordArrayToUint8Array,
  createUserKey,
  encodeReq,
  formCheck,
  XOR,
} from "../functions/encoding";
import FeedbackAlert from "./FeedbackAlert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import BannerImage from "./BannerImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faEyeSlash,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { getUserFromEmail, getEmailFromId } from "../functions/getUser.js";
library.add(faEye, faEyeSlash, faCircleInfo);

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function New(props) {
  const [files, setFiles] = useState([]);
  const isFilesEmpty = files.length === 0;

  const [isFileLimitCapped, setIsFileLimitCapped] = useState(false);
  const [isUnpaidUser, setIsUnpaidUser] = useState(false);
  const [isSponsored, setIsSponsored] = useState(false);

  const [feedback, setFeedback] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [user, setUser] = useState(props.user);
  useEffect(() => {
    if (props.user) {
      setUser(props.user);
      const abort = new AbortController();
      checkFileLimit(abort.signal);

      return () => {
        abort.abort();
      };
    }
  }, [props.user]);
  useEffect(() => {}, [user]);
  const navigate = useNavigate();

  const [fileData, setFileData] = useState({
    count: 0,
    limit: 0,
  });
  function removeFile(index) {
    setFiles(files.filter((v, i) => i !== index));
  }

  async function checkFileLimit(signal) {
    const userData = (await getUserFromEmail(props.user.email)).userData;
    if (userData.sponsor_id) {
      setIsSponsored(true);
    }
    await axios
      .post(
        `${serverUrl}checkFileLimit`,
        {
          userId: userData.sponsor_id ? userData.sponsor_id : props.user.id,
        },
        { signal }
      )
      .then((res) => {
        const data = res.data.data;
        console.log("for file data", data);
        setIsFileLimitCapped(res.data.data.fileLimit_capped);
        setFileData({
          count: data.fileCount,
          limit: data.fileLimit,
        });
        console.log(isSponsored + " " + userData.sponsor_id);
        if (
          res.data.data.fileLimit === 0 &&
          res.data.data.fileShare === 0 &&
          isSponsored === false
        ) {
          setIsUnpaidUser(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code !== "ERR_CANCELED") {
        }
      });
  }

  /**
   * Converts a CryptoJS WordArray into an 8 bit array
   * @param {WordArray} wa - 8 bit data encoded into a CryptoJS word array (32 bit words)
   * @returns {Uint8Array} - 8 bit array containing the hex data
   */
  function wordArrayToBytes(wa) {
    let bytes = [];
    for (var i = 0; i < wa.sigBytes; ++i) {
      var j = 24 - (i % 4) * 8;
      bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
    }
    return new Uint8Array(bytes);
  }

  async function encryptAndUploadFile(
    uploadName,
    files,
    password,
    pushFeedback = () => {}
  ) {
    // create an instance of JSZip
    let zip = new JSZip();
    // add all the files to the zip
    files.forEach((file) => {
      zip.file(file.name, file);
    });

    let blob, zipFile;
    try {
      pushFeedback({
        variant: "info",
        loading: true,
        message: `Verifying Password for ${user.name}`,
      });

      const isPwdValid = await verifyPassword(user.id, password, () => {
        navigate("/unlock");
      });

      if (isPwdValid !== true) {
        pushFeedback({
          variant: "warning",
          message: `Password incorrect for user ${user.name}(${user.email}).`,
        });
        return;
      }
      pushFeedback({
        variant: "info",
        loading: true,
        message: `Compressing contents...`,
      });
      blob = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
      zipFile = new File([blob], `${uploadName}.zip`, {
        type: "blob",
        compression: "DEFLATE",
      });
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `A problem occurred when trying to verify your password and
                  compressing the files.`,
      });
      return;
    }
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Encrypting file...`,
    });

    // upload file using doUpload
    const randomKey = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.lib.WordArray.random(16).toString()
    );

    const reader = new FileReader();
    if (zipFile.size > 100000000) {
      pushFeedback({
        variant: "warning",
        message: `File is too large! If your file is larger than 100MB after compression, you cannot use our service to share it`,
      });
      return;
    }
    reader.onload = () => {
      const file = reader.result;
      const fileAsBytes = new Uint8Array(file);

      //create file signature
      const fileSig = CryptoJS.SHA256(file);
      const fileSigAsBytes = convertWordArrayToUint8Array(fileSig);

      //create new array buffer with file signature attached to end
      const combined = new ArrayBuffer(
        fileAsBytes.length + fileSigAsBytes.length
      );
      const combinedRef = new Uint8Array(combined);

      let i = 0,
        j = 0;
      for (i = 0; i < combinedRef.length; ++i) {
        if (i < fileAsBytes.length) {
          // place file bytes in first part of combined array
          combinedRef[i] = fileAsBytes[i];
        } else {
          // place signature bytes at end
          combinedRef[i] = fileSigAsBytes[j++];
        }
      }

      const combinedAsWordArray = CryptoJS.lib.WordArray.create(combined);

      // file encrypted
      // iv is wordarray, encrypted data is something crypto cypherparams
      var encrypted = iv.concat(
        CryptoJS.AES.encrypt(combinedAsWordArray, randomKey, {
          mode: CryptoJS.mode.CFB,
          iv: iv,
        }).ciphertext
      );

      const encrypted_ba = wordArrayToBytes(encrypted);
      const encryptedFileBlob = new Blob([encrypted_ba]);
      const encryptedFileName = `${zipFile.name}.enc`;
      const encryptedFile = new File([encryptedFileBlob], encryptedFileName);

      const metadata = {
        name: encryptedFileName,
        mimeType: "application/octet-stream",
      };

      // create data as FormData for upload
      const formData = new FormData();
      // add association key values
      formData.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );

      formData.append("file", encryptedFile);
      formData.append("upload_file", true);

      pushFeedback({
        variant: "info",
        loading: true,
        message: `Uploading file...`,
      });
      const accessToken = window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse().access_token;
      const contentType = "application/octet-stream";
      axios
        .post(
          `https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable`,
          {
            name: encryptedFileName,
            mimeType: contentType,
            "Content-Type": contentType,
            "Content-Length": encryptedFile.size,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          const newURI = res.headers.location;

          axios
            .put(newURI, encryptedFile, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": contentType,
                "X-Upload-Content-Type": contentType,
              },
            })
            .then((res) => {
              sendTransformed(
                res.data.id,
                encryptedFileName,
                encryptedFile.size,
                password,
                randomKey,
                user,
                pushFeedback
              );
            })
            .catch((err) => {
              pushFeedback({
                variant: "danger",
                message: `An error occurred while uploading the file contents.`,
              });
            });
        })
        .catch((err) => {
          pushFeedback({
            variant: "danger",
            message: `An error occurred while creating the file.`,
          });
        });
    };

    reader.readAsArrayBuffer(zipFile);
  }

  function sendTransformed(
    fileId,
    fileName,
    fileSize,
    pwd,
    randomKey,
    user,
    pushFeedback = () => {}
  ) {
    const userKey = createUserKey(fileId, pwd, user.id);
    const randomKeyAsBytes = convertWordArrayToUint8Array(randomKey);
    const userKeyXORRandomKey = XOR(userKey, randomKeyAsBytes);
    const passCheck = formCheck(pwd + user.id);
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Saving Encryption Key...`,
    });

    axios
      .post(
        `${serverUrl}addTransformedKey`,
        encodeReq(
          {
            fileId: fileId,
            transformed: userKeyXORRandomKey,
            fileName: fileName,
            fileSize: fileSize,
            toCheck: passCheck,
            userId: user.id,
          },
          user.email
        )
      )
      .then((res) => {
        pushFeedback({
          variant: "success",
          message: "Successfully uploaded file!",
        });
        navigate({
          pathname: `/open`,
          search: `?${createSearchParams({
            fileId,
            userId: user.id,
          })}`,
        });
      })
      .catch((err) => {
        console.log(err);
        pushFeedback({
          variant: "danger",
          message: `An unexpected error occurred while saving file key.`,
        });
      });
  }
  const helpContent = (
    <p className="mb-1">
      Users can select one or more files from their computer for encryption by
      clicking on or dropping files in the big, dotted rectangle. Selected files
      are compressed and gathered in a single zipped folder. This folder is then
      added to the user's Google Drive and listed under their file table.
    </p>
  );
  return (
    <ComponentCard
      title="Encrypt and Upload to Google Drive"
      helpContent={helpContent}
    >
      {!isUnpaidUser && isFileLimitCapped && !isSponsored ? (
        <Alert variant="danger" className="text-center">
          You have reached your
          <br />
          <h6>File Storage Limit </h6>
          <div>
            <Link to="/select-file-chunks">Buy More Encryptions,</Link>{" "}
            <Link to="/show-service-class">Upgrade Your Plan,</Link>
            <br /> or delete files from your{" "}
            <Link to="/sheets">File Table</Link>.
          </div>
        </Alert>
      ) : null}
      {isSponsored && isFileLimitCapped ? (
        <Alert variant="danger" className="text-center">
          Your sponsorship has reached its
          <br />
          <h6>File Storage Limit. </h6>
          <div>
            Request your Sponsor to purchase more chunks
            <br /> or delete files from your{" "}
            <Link to="/sheets">File Table</Link>.
          </div>
        </Alert>
      ) : null}
      {isUnpaidUser && !isSponsored ? (
        <Alert variant="danger">
          You have not subscribed to Cynorix fileshare yet.
          <br />
          <div>
            <Link to="/free-trial">Try our freetrial</Link>
            &nbsp;or&nbsp;
            <Link to="/show-service-class">Upgrade Your Plan</Link>
          </div>
        </Alert>
      ) : null}
      <Row>
        <Col md={12} sm={12} style={{ marginBottom: 10 }}>
          <Card
            className="Row"
            style={{
              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <FileUploadBox
              className="Row"
              handleFileUpload={(uploads) => {
                // uploads is a FileList, iterate and add to files
                const uploadArray = [];
                for (const file of uploads) {
                  uploadArray.push(file);
                }
                setFiles(files.concat(uploadArray));
              }}
              style={{
                minHeight: 120,
                height: files.length > 0 ? 120 : "100%",
                display: "flex",
                borderRadius: 10,
                margin: 10,
                border: "2px dashed gray",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card.Text>Click to add or drop files here...</Card.Text>
            </FileUploadBox>
            {/* List of uploaded files*/}
            <Card.Body
              style={{
                display: files.length > 0 ? null : "none",
              }}
            >
              <span
                className="ms-3"
                style={{
                  color: "#424242",
                }}
              >
                <strong>Files Uploaded</strong>
              </span>
              <ListGroup
                style={{
                  maxHeight: "20vh",
                  overflow: "scroll",
                  width: "100%",
                }}
              >
                {files.map((file, index) => {
                  return (
                    <ListGroup.Item key={index}>
                      {`${file.name} - ${bytesToString(file.size)}`}
                      <CloseButton
                        style={{ float: "right" }}
                        aria-label="Remove Upload"
                        onClick={(e) => {
                          removeFile(index);
                        }}
                      />
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        {/* The Encrypt Form */}
        <Col md={12} sm={12}>
          {/* This is currently hard coded like the original...*/}
          <Form
            noValidate
            // validated={isFormValidated}
            onSubmit={(e) => {
              e.preventDefault();
              if (e.currentTarget.checkValidity() === false) {
                if (e.target.accountPass.value === "") {
                  setFeedback({
                    variant: "warning",
                    message: `Please enter your password.`,
                  });
                }
                e.stopPropagation();
              } else {
                encryptAndUploadFile(
                  e.target.uploadName.value,
                  files,
                  e.target.accountPass.value,
                  setFeedback
                );
              }
            }}
          >
            <fieldset disabled={isFileLimitCapped}>
              <Form.Group className="mb-3 row" controlId="upload-name">
                <Form.Label className="col-md-2 col-form-label">
                  Package Name
                </Form.Label>
                <Col md={10} sm={12}>
                  <Form.Control
                    placeholder="Name of Upload"
                    name="uploadName"
                    defaultValue={new Date().toLocaleString()}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid filename.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row" controlId="upload-pass">
                <Form.Label className="col-md-2 col-form-label">
                  Password
                </Form.Label>
                <Col xs={12} sm={12} md={10}>
                  <div className="pass-wrapper">
                    <input
                      className="form-control"
                      type={passwordShown ? "text" : "password"}
                      name="accountPass"
                      placeholder="Password"
                      required
                    />
                    <i className="test">
                      <FontAwesomeIcon
                        icon={passwordShown ? "eye-slash" : "eye"}
                        onClick={togglePassword}
                      />
                    </i>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please fill in your password.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Button
                type="submit"
                className="mb-2"
                disabled={isFilesEmpty || (feedback && feedback.loading)}
                style={{ width: "100%" }}
              >
                Submit
              </Button>
              <FeedbackAlert feedback={feedback} />
            </fieldset>
          </Form>
        </Col>
      </Row>
      <hr className="my-2" />
      <span>
        <strong>Note: </strong> Files that bigger than 100MB after compression
        cannot be shared.
      </span>
      <br></br>
      <span>
        {fileData.count} {fileData.count === 1 ? "file" : "files"} -{" "}
        {fileData.limit} file limit
      </span>
    </ComponentCard>
  );
}
