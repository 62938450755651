import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BannerImage from "../BannerImage";
import FeedbackAlert from "../FeedbackAlert";
import LoadingButton from "../LoadingButton";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function UnlockAccount(props) {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [otpTimerFeedback, setOtpTimerFeedback] = useState(null);
  const [isLocked, setIsLocked] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [displayTime, setDisplayTime] = useState("");
  const [SMSsent, setSMSsent] = useState(false);
  const [sentOnce, setSentOnce] = useState(false);
  const [userLSApiRetryCount, setUserLSApiRetryCount] = useState(0);
  const MAX_RETRIES = 5;

  useEffect(() => {
    setFeedback({
      loading: true,
      message: "Checking if your account is locked...",
      variant: "info",
    });
    axios
      .post(SERVER_URL + "getUserLockStatus", {
        userId: props.user.id,
      })
      .then((res) => {
        if (res.data.data) {
          setFeedback({
            loading: false,
            message: "Please confirm credentials",
            variant: "info",
          });
        } else {
          setFeedback({
            loading: true,
            message: "Your account is not locked. Returning...",
            variant: "warning",
          });
          setTimeout(() => {
            navigate("/new");
          }, 1500);
        }
      })
      .catch((err) => {
        if (userLSApiRetryCount < MAX_RETRIES) {
          setUserLSApiRetryCount((x) => x + 1);
        } else {
          setFeedback({
            message:
              "An error occurred while checking if your account is locked",
            variant: "danger",
          });
        }
      });
  }, [props.user, userLSApiRetryCount]);

  const sendCode = () => {
    setFeedback({
      loading: true,
      message: "Sending OTP",
      variant: "info",
    });
    axios
      .post(
        SERVER_URL + "requestSMSOTP",
        { userId: props.user.id },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setOtpTimerFeedback({
          loading: false,
          message: `OTP Sent, time remaining: ${displayTime}`,
          variant: "secondary",
        });
        setFeedback(null);
        setSentOnce(true);
        setSMSsent(true);
        setTimeLeft(185);
      })
      .catch((err) => {
        setFeedback({
          loading: false,
          message: `An Error Occured`,
          variant: "danger",
        });
      });
  };

  useEffect(() => {
    let timerId;

    if (SMSsent) {
      timerId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [SMSsent]);

  useEffect(() => {
    if (timeLeft < 0 && SMSsent) {
      setSMSsent(false);
      setTimeLeft(0);
      setOtpTimerFeedback({
        loading: false,
        message: `OTP Expired, please resend code to continue`,
        variant: "warning",
      });
    } else if (timeLeft >= 0 && SMSsent) {
      setDisplayTime(
        Math.floor(timeLeft / 60) +
          ":" +
          (timeLeft % 60 < 10 ? "0" : "") +
          (timeLeft % 60)
      );
    }
  }, [timeLeft, SMSsent]);

  useEffect(() => {
    if (SMSsent) {
      setOtpTimerFeedback({
        loading: false,
        message: `OTP Sent, time remaining: ${displayTime}`,
        variant: "secondary",
      });
    }
  }, [displayTime, SMSsent]);

  const onSubmit = (e) => {
    e.preventDefault();
    setFeedback({
      loading: true,
      message: "Verifying OTP...",
      variant: "info",
    });
    axios
      .post(SERVER_URL + "unlockAccount", {
        userId: props.user.id,
        otp: code,
      })
      .then((res) => {
        if (res.data.success) {
          setFeedback({
            loading: true,
            message: "Account Unlocked! Redirecting...",
            variant: "success",
          });
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        } else {
          setFeedback({
            message: "An error occurred.",
            variant: "danger",
          });
        }
      })
      .catch((err) => {
        if (err.response.data.errormsg === "Wrong OTP") {
          setFeedback({
            message: "Wrong OTP. Try again",
            variant: "danger",
          });
        } else {
          setFeedback({
            message: "Something went wrong while verifying OTP",
            variant: "danger",
          });
        }
      });
  };

  return (
    <Card
      body
      className="pb-0"
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      {/* Nav eventually goes here */}
      <h4 className="mt-3">
        <b>Unlock Account</b>
      </h4>
      <hr className="mt-0" />
      <p className="mb-3">
        {isLocked
          ? `You have reached the maximum attempts on your password. Please enter the
        confirmation code sent to your phone.`
          : `Your account is not locked.`}
      </p>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={onSubmit}
      >
        <Form.Group>
          <Row>
            <Col
              xs={12}
              lg={2}
              style={{
                display: "flex",

                alignItems: "center",
                padding: 0,
              }}
              className={`mb-2 mb-lg-0 
                              justify-content-center justify-content-lg-end`}
            >
              SMS Confirmation
            </Col>
            <Col xs={12} lg={8}>
              <Form.Control
                type="text"
                value={code}
                placeholder="Code"
                autoFocus
                name="code"
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />
            </Col>
            <Col xs={12} lg={2}>
              <Button
                onClick={() => {
                  sendCode();
                }}
                disabled={SMSsent}
                className="w-100"
                variant="danger"
                style={{ backgroundColor: "#800000", border: "none" }}
              >
                {sentOnce ? "Resend" : "Send Code"}
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <LoadingButton
          type="submit"
          loading={feedback && feedback.loading}
          disabled={!SMSsent || (feedback && feedback.loading)}
          className="w-100 mt-3"
        >
          Submit
        </LoadingButton>
      </Form>
      <FeedbackAlert feedback={otpTimerFeedback} className="mt-3 mb-2" />
      <FeedbackAlert feedback={feedback} className="mt-3 mb-0" />
    </Card>
  );
}
