import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Row,
  Col,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { auth } from "../firebase";
import { getUserFromEmail, getEmailFromId } from "../functions/getUser";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import BannerImage from "./BannerImage";
import PasswordWrapper from "./PasswordWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const testData = [
  {
    first_name: "bob",
    last_name: "jones",
    email: "asdf@asdf.com",
  },
  {
    first_name: "bob",
    last_name: "jones",
    email: "asdf@asdf.com",
  },
];

export default function AcceptSponsorshipReq(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeSuccess, setCodeSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [listOfEmployee, setListOfEmployee] = useState([]);
  const navigate = useNavigate();
  const [requestCount, setRequestCount] = useState(0);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({})
  const [passwordShown, setPasswordShown] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setError("");
    navigate("/service-usage-info");
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setCodeError("");
    setLoading(true);
    // const userData = await getUserFromEmail(auth.currentUser.email);
    // const verified = await verifyCode(codeRef.current.value, userData.user_id);
    const verified = true //change to check for sms
    if (verified) {
      setCodeSuccess("Code correct.");
      setTimeout(() => {
        setCodeSuccess("");
        setLoading(false);
        setShow(false);
      }, 1000);
    } else {
      setCodeError("Incorrect code.");
      setLoading(false);
    }
  };

  const codeRef = useRef();

  useEffect(() => {
    (async () => {
      await loadPage();
    })();
  }, []);

  const loadPage = async () => {
    try {
    //   setShow(true);
      const userEmail = props.user.email;
      const userData = (await getUserFromEmail(userEmail)).userData;
      setUserData(userData)
      const { sponsor_request_list } = userData;
      if (sponsor_request_list) {
        setRequestCount(Object.keys(sponsor_request_list).length);
        let list = [];
        for (const property in sponsor_request_list) {
          const employeeEmail = await getEmailFromId(property);
          list.push({email: employeeEmail, id: property});
        }
        setListOfEmployee(list);
      }
    } catch (err) {
      console.log(err)
      alert("Error displaying page.");
      // console.log(err);
    }
  };

  const handleAccept = async (employee) => {
		
		try {
			setError('');
			setLoading(true);
			// check if employee is still in the SponsorShipRequestsList
			const newUserData = (await getUserFromEmail(props.user.email)).userData;
			const { sponsor_request_list } = newUserData;
			if (!sponsor_request_list || !Object.hasOwn(sponsor_request_list, employee.id)) {
				setError('The user is no longer requesting sponsorship.');
				setTimeout(() => {
					navigate('/accept-sponsorship-req');
				}, 3000);
				return;
			}
			// check if employee is already being sponsored
			let userSponsored = await checkSponsorshipStatus(
			  props.user.id,
				employee.id
			);

			if (userSponsored) {
				setError('You are already sponsoring this user');
				setTimeout(() => {
					navigate('/service-usage-info');
				}, 3000);
				return;
			}
			await updateSponsorship(
				employee.id,
        props.user.id,
        props.user.name
			);
			setListOfEmployee(listOfEmployee.filter((e) => e.id !== employee.id));
      let newRequestCount = requestCount
      newRequestCount--
      setRequestCount(newRequestCount)
		} catch {
			setError('Failed to accept');
      alert("Error accepting sponsorship.")
			setLoading(false);
		}
	};

  const handleDecline = async (employee) => {
    await removeSponsorRequest(props.user.id, employee.id);
    setListOfEmployee(listOfEmployee.filter((e) => e.id !== employee.id));
    let newRequestCount = requestCount
    newRequestCount--
    setRequestCount(newRequestCount)
  };

  const updateSponsorship = async (
    employeeId,
    sponsorId,
    sponsorName
  ) => {
    await Axios.post(SERVER_URL + "updateSponsorship", {
      employee_id: employeeId,
      sponsor_id: sponsorId,
      sponsor_name: sponsorName
    })
      .then((res) => {
        console.log(res)
        setSuccess("Accepted Sponsorship");
        setTimeout(() => {
            navigate("/new");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const sendResponseEmail = async (senderEmail, receiverEmail) => {
    // send email to notify employee that the request is accepted
    await Axios.post(SERVER_URL + "sendResponseEmail", {
      sender: senderEmail,
      receiver: receiverEmail,
    })
      .then(() => {
        // console.log('Sent email');
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const checkSponsorshipStatus = async (sponsorId, employeeId) => {
    return await Axios.post(SERVER_URL + "checkSponsorshipStatus", {
      sponsor_id: sponsorId,
      employee_id: employeeId,
    })
      .then((response) => {
        if (response.data.status === true) {
          // console.log('You are already sponsoring this user');
        }
        return response.data.data.status;
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const removeSponsorRequest = async (sponsorId, employeeId) => {
    await Axios.post(SERVER_URL + "removeFromSponsorshipRequestList", {
      sponsor_id: sponsorId,
      employee_id: employeeId,
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  return (
    <>
      <Card
        body
        className="pb-0"
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <BannerImage url="/img/CYN_Cynorix.jpg" />
        <h4 className="mt-3">
          <b>Accept/Decline Sponsorship Requests</b>
        </h4>
        <hr className="mt-0" />
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        
        <PasswordWrapper requiresOTP user={props.user}>
          {requestCount === 0 && "You have no requests"}
          <ListGroup className="mb-3" style={{ minHeight: 50 }}>
            {listOfEmployee.map((employee, index) => {
              return (
                <ListGroup.Item key={index + 1} id={index + 1}>
                  <Form.Group id={employee.email}>
                    <Row>
                      <Col>
                        <Form.Label column>{employee.email}</Form.Label>
                      </Col>
                      <Col xs={12} md={"auto"}>
                        <Row className="mx-0">
                          <Col className="w-auto px-0 me-2">
                            <Button
                              disabled={loading}
                              variant="secondary"
                              className="w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDecline(employee);
                              }}
                            >
                              Decline
                            </Button>
                          </Col>
                          <Col className="w-auto px-0">
                            <Button
                              disabled={loading}
                              className="w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAccept(employee);
                              }}
                            >
                              Accept
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        
        <hr className="my-2" />
        <span className="me-1 ">
          {requestCount} {requestCount === 1 ? "Request" : "Requests"}
        </span>
        </PasswordWrapper>
      </Card>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            2FA is Required to Accept/Decline Sponsorship Requests
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Please enter your Google Authenticator code to continue.
              </Form.Label>
              {codeError && <Alert variant="danger">{codeError}</Alert>}
              {codeSuccess && <Alert variant="success">{codeSuccess}</Alert>}
              {/* {isSponsor && removed && (
                <Alert variant="success">{removed}</Alert>
              )} */}
              <div className="pass-wrapper">
                <input
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    ref={codeRef}
                    required
                />
                <i className="test">
                  <FontAwesomeIcon
                      icon={passwordShown ? "eye-slash" : "eye"}
                      onClick={(event) => {
                        event.preventDefault();
                        setPasswordShown(!passwordShown)
                      }}
                  />
                </i>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleContinue} disabled={loading}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
