import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Col, Row, Alert, Modal } from "react-bootstrap";
import { auth } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import { getUserFromEmail } from "../functions/getUser";
import LoadingButton from "./LoadingButton";
import BannerImage from "./BannerImage";
import PasswordWrapper from "./PasswordWrapper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// need to add in functions to generate email and verify authentication

export default function SelectSponsor(props) {
  const emailRef = useRef("");
  const codeRef = useRef("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasSponsorships, setHasSponsorships] = useState(false);
  const [paid, setPaid] = useState(false);
  const navigate = useNavigate();

  ////////Modal
  const [show, setShow] = useState(false);

  useEffect(() => {
    const renderAccessStatus = async () => {
      const user = await getUserFromEmail(props.user.email);
      if (user.userData.paid) {
        setPaid(true);
      }
      await Axios.post(`${SERVER_URL}getUserSponsorship`, {
        user_id: props.user.id,
      })
        .then((res) => {
          const data = res.data.data;
          if (data) {
            if (
              Object.hasOwn(data, "list_of_sponsorships") ||
              Object.hasOwn(data, "sponsor_id")
            ) {
              handleShow();
            }
          } else {
            return null;
          }
        })
        .catch((err) => {
          throw err;
        });
    };
    renderAccessStatus();
  }, [props.user]);

  const handleClose = () => {
    setShow(false);
    setError("");
    navigate("/new");
  };
  const handleShow = () => {
    setShow(true);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const userEmail = auth.currentUser.email;
    const userEmail = props.user.email;
    const sponsorEmail = emailRef.current.value;
    setError("");
    setLoading(true);

    if (userEmail === sponsorEmail) {
      setError("Unable to request sponsorship. Please provide a valid email.");
      setLoading(false);
      return;
    }
    try {
      const sponsorData = (await getUserFromEmail(sponsorEmail)).userData;
      if (!sponsorData.paid) {
        setError(
          "Selected account does not have permission to provide sponsorship."
        );
        setLoading(false);
        return;
      }
    } catch (err) {
      // console.log(err);
      if (err == "Error: User Not Found") {
        setError(
          "Selected sponsor does not exist, please provide a valid email."
        );
      } else {
        setError("An unexpected error has occurred.");
      }
      setLoading(false);
      return;
    }
    try {
      const receiverUserId = (await getUserFromEmail(emailRef.current.value))
        .id;
      await sendRequest(
        userEmail,
        emailRef.current.value,
        props.user.id,
        receiverUserId,
        props.user.name
      );
      setSuccess("Request Sent");
      setTimeout(() => {
        navigate("/new");
      }, 1000);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setError("An error has occurred.");
    }
  };

  const sendRequest = async (
    sender,
    receiver,
    senderID,
    receiverID,
    senderName
  ) => {
    await Axios.post(SERVER_URL + "createSponsorship", {
      sender: sender,
      receiver: receiver,
      sender_ID: senderID,
      receiver_ID: receiverID,
      sender_name: senderName,
    })
      .then((response) => {
        // console.log('Email sent successfully');
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <BannerImage url="/img/CYN_Cynorix.jpg" />
          <h4 className="mt-3">
            <b>Select A Sponsor</b>
          </h4>
          <hr className="mb-3 mt-1" />
          <PasswordWrapper requiresOTP user={props.user}>
            <Row>
              <Col>
                {success && <Alert variant="success">{success}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <p>Send a request to a sponsor to cover service fees</p>
                {paid && (
                  <p>
                    <b>Notice: </b>
                    You are currently subscribed. Note that becoming sponsored
                    will end your current subscription.
                  </p>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="sponsor_email">
                    <Row className="mb-2">
                      <Col
                        lg={2}
                        md={3}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                        className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                      >
                        <label htmlFor="fileUses">Enter Sponsor's Email</label>
                      </Col>
                      <Col lg={10} md={9} sm={12} xs={12}>
                        <input
                          name="uses"
                          className="form-control"
                          type="email"
                          ref={emailRef}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <LoadingButton
                    loading={loading}
                    className="mt-3 w-100"
                    type="submit"
                  >
                    Request Sponsorship
                  </LoadingButton>
                </Form>
              </Col>
            </Row>
          </PasswordWrapper>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>You Do Not Have Access To This Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>You will now be redirected to the dashboard</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
