import axios from "axios";
import { useState, useEffect } from "react";
import {
  createUserKey,
  encodeReq,
  formCheck,
  uint8ArrayToWordArray,
  XOR,
  arraysEqual,
  convertWordArrayToUint8Array,
} from "../functions/encoding";
import CryptoJS from "crypto-js";

export const serverUrl = process.env.REACT_APP_SERVER_URL;

const keyLabel = "cynorix_key";
const expireLabel = "cynorix_expire";
const registeredLabel = "registered";


export function useSecureCommunication(user, gapi) {
  const [isSessionExpired, setIsSessionExpired] = useState(true);
  const [isKeyGenerating, setIsKeyGenerating] = useState(false);

  function refreshKey() {
    setIsSessionExpired(false);
    genKey();
  }

  // Generate the key for the session
  async function genKey() {
    setIsKeyGenerating(true);

    // for now, send request to backend with some basic info
    axios
      .post(`${serverUrl}generateKey`, {
        userExists: true,
        userEmail: user.email,
        pbValidVecBase64: null,
      })
      .then((res) => {
        setIsKeyGenerating(false);
      })
      .catch((err) => {
        setIsKeyGenerating(false);
      });
  }


  useEffect(() => {
    beginSession();

    return () => {
      endSession();
    };
  }, []);

  //calculates time from key expiry, and sets timeout to block page when key expires
  function beginSession() {
    var expire = localStorage.getItem(expireLabel);

    var currDate = new Date();
    var expireDate = new Date(parseInt(expire));
    var genDate = new Date(parseInt(expire));

    genDate.setMinutes(expireDate.getMinutes() - 30);
    expireDate.setMinutes(expireDate.getMinutes() - 2); //add 2 minute buffer

    var diffInMs = expireDate.getTime() - currDate.getTime();
    sessionTimeout = setTimeout(endSession, diffInMs);

  }

  //triggers modal to indicate session has ended
  function endSession() {
    setIsSessionExpired(true);
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }

  return {
    refreshKey,
    isKeyGenerating,
  };
}

var sessionTimeout;

window.addEventListener("beforeunload", () => {
  if (localStorage.getItem(expireLabel) === "generating") {
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }
});


// --- general use functions --- //

// updates database to match files in google drive (in case user has trashed/deleted files)
function updateFiles(gapi, user) {
  //request list of trashed files on google drive
  const trashedPromise = new Promise((resolve, reject) => {
    gapi.client.drive.files
      .list({
        copora: "user",
        q: "name contains '.enc' and trashed = true",
      })
      .then(
        function (response) {
          var rawFiles = response.result.files;

          var trashedIds = [];
          for (var i = 0; i < rawFiles.length; ++i) {
            trashedIds.push(rawFiles[i].id);
          }
          resolve(trashedIds);
        },
        function (err) {
          reject(err);
        }
      );
  });

  //request list of untrashed files on google drive
  const untrashedPromise = new Promise((resolve, reject) => {
    gapi.client.drive.files
      .list({
        corpora: "user",
        q: "name contains '.enc' and trashed = false",
      })
      .then(
        function (response) {
          var rawFiles = response.result.files;

          var untrashedIds = [];
          for (var i = 0; i < rawFiles.length; ++i) {
            untrashedIds.push(rawFiles[i].id);
          }
          resolve(untrashedIds);
        },
        function (err) {
          reject(err);
        }
      );
  });

  Promise.all([trashedPromise, untrashedPromise])
    .then((files) => {
      //request to update files on server
      var xhr = new XMLHttpRequest();
      var getFilesReq = {
        userId: user.id,
        trashed: files[0],
        untrashed: files[1],
      };
      xhr.open("POST", serverUrl + "updateFiles", true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.responseType = "json";
      xhr.onload = function () {

        if (xhr.status == 500) {
          //
        } else {
        }
      };
      xhr.onerror = function (err) {
      };
      xhr.send(encodeReq(getFilesReq, user.email));
    })
    .catch((err) => {
    });
}

export async function verifyPassword(
  userId,
  password,
  redirectIfLocked = () => {}
) {
  const check = formCheck(password + userId);

  return new Promise((resolve, reject) => {
    axios
      .post(`${serverUrl}verifyPassword`, {
        userId: userId,
        toCheck: check,
      })
      .then((res) => {

        if (typeof res.data.data.valid === "boolean") {
          // temp fix
          if (res.data.data.locked === true) {
            reject("Account is locked.");
            redirectIfLocked();
          }
          resolve(res.data.data.valid);
        } else {
          reject("Valid is not a boolean type: " + res.data.data.valid);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function verifyLockedPassword(
  userId,
  password
) {
  const check = formCheck(password + userId);

  return new Promise((resolve, reject) => {
    axios
      .post(`${serverUrl}verifyPassword`, {
        userId: userId,
        toCheck: check,
      })
      .then((res) => {
        if (typeof res.data.data.valid === "boolean") {

          resolve(res.data.data.valid);
        } else {
          reject("Valid is not a boolean type: " + res.data.data.valid);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// uses gmail api to send email from current user's account
export function sendEmail(subject, emailMsg, recipient, user) {
  //compose email and encode to base64 string
  const messageParts = [
    "From: " + user.email,
    "To: " + recipient,
    "Content-Type: text/html; charset=utf-8",
    "MIME-Version: 1.0",
    `Subject: ${subject}`,
    "",
    emailMsg,
  ];
  const message = messageParts.join("\n");
  var encodedMessage = btoa(message);

  return new Promise((resolve, reject) => {
    window.gapi.client.gmail.users.messages
      .send({
        userId: "me",
        resource: {
          raw: encodedMessage,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject("sending email failed: " + err);
      });
  });
}

function bytesToWordArray(bytes) {
  var words = [];
  for (var i = 0; i < bytes.length; ++i) {
    var j = 24 - (i % 4) * 8;
    words[i >>> 2] |= bytes[i] << j;
  }
  return CryptoJS.lib.WordArray.create(words, bytes.length);
}

export async function downloadFile(
  pwd,
  fileId,
  user,
  pushFeedback = () => {},
  redirectIfLocked = () => {}
) {
  // validate password
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Validating Password...`,
  });
  try {
    const isPwdValid = await verifyPassword(user.id, pwd, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "warning",
        message: `Password incorrect for user ${user.name}(${user.email}).`,
      });
      return;
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occurred while verifying password.`,
    });
  }

  // get file contents
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Retrieving File Contents...`,
    });
    const res = await window.gapi.client.drive.files.get({
      fileId: fileId,
      alt: "media",
    });

    await getTransformed(pwd, res.body, fileId, user, pushFeedback);
  } catch (err) {
    const reason = err.result.error.errors[0].reason;
    if (reason === "notFound") {
      pushFeedback({
        variant: "danger",
        message: `File not found.`,
      });
    } else {
      pushFeedback({
        variant: "danger",
        message: `An unexpected error occurred while getting the file.`,
      });
    }
  }
}
function str2ab(str) {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
async function getTransformed(
  pwd,
  fileContent,
  fileId,
  user,
  pushFeedback = () => {}
) {
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Decrypting file...`,
  });
  // reform user encryption key
  var userKey = createUserKey(fileId, pwd, user.id);
  var pwdCheck = formCheck(pwd + user.id);
  try {
    const res = await axios.post(`${serverUrl}getTransformedKey`, {
      fileId: fileId,
      toCheck: pwdCheck,
      userId: user.id,
    });

    // MAKE SURE THAT THE FILE EXISTS AND CLAIMED IS TRUE
    const { exists, claimed } = res.data.data;
    if (exists === false) {
      pushFeedback({
        variant: "danger",
        message: `The file you are looking for cannot be found.`,
      });
      return;
    } else if (claimed === false) {
      pushFeedback({
        variant: "danger",
        message: `File Unclaimed.`,
      });
      return;
    }

    // GET KEY FOR DECRYPTING FILE
    const encFileKey = res.data.data.key;
    const fileKeyAsBytes = XOR(encFileKey, userKey);
    const fileKeyAsWords = uint8ArrayToWordArray(fileKeyAsBytes);

    var tempcontent_wordarray = bytesToWordArray(
      new Uint8Array(str2ab(fileContent))
    );
    // getting the iv as wordarray
    const iv = CryptoJS.lib.WordArray.create(
      tempcontent_wordarray.words.slice(0, 4)
    );
    // getting file content for decryption
    var file_content = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.lib.WordArray.create(
        tempcontent_wordarray.words.slice(4)
      ),
    });
    // decrypting
    const decrypted = CryptoJS.AES.decrypt(file_content, fileKeyAsWords, {
      mode: CryptoJS.mode.CFB,
      iv: iv,
    });
    const decryptedAsBytes = convertWordArrayToUint8Array(decrypted);
    // separate file signature from file
    const decLength = decryptedAsBytes.length;
    const sigLength = 32;
    const sigAsBytes = decryptedAsBytes.slice(decLength - sigLength, decLength);
    const fileAsBytes = decryptedAsBytes.slice(0, decLength - sigLength);

    // get hashed signature of decrypted file to compare with expected signature
    const sigCheckWords = CryptoJS.SHA256(decryptedAsBytes); // create file signature
    const sigCheckAsBytes = convertWordArrayToUint8Array(sigCheckWords);

    // download file if signature is correct
    if (!arraysEqual(sigAsBytes, sigCheckAsBytes)) {

      pushFeedback({
        variant: "danger",
        message: `The file is corrupted or was incorrectly decrypted. Download aborted.`,
      });
      return;
    }

    var accessToken = window.gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse().access_token;
    try {
      const res = await axios.get(
        `https://www.googleapis.com/drive/v3/files/${fileId}`,
        {
          params: {
            fields: "name",
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const fileAsBlob = new Blob([fileAsBytes]);
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(fileAsBlob);
      var filename = res.data.name;
      a.href = url;
      a.download = filename.replace(".enc", "");
      a.click();
      window.URL.revokeObjectURL(url);
      pushFeedback(null);
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `Error occured while getting title of file. Download aborted.`,
      });
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occured while tryihng to retrieve the 
        file's transformed key. Please try again.`,
    });
  }
}

export function getFileName(fileId) {
  var accessToken = window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse().access_token;
  return new Promise((resolve, reject) => {
    axios
      .get(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
        params: {
          fields: "name",
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        resolve(res.data.name);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
