import Axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getUserFromEmail = async (email) => {
	email = email.toLowerCase();
	return await Axios.post(SERVER_URL + 'getUserFromEmail', {
		userEmail: email,
	})
		.then((response) => {
			return response.data.data;
		})
		.catch((err) => {
			//
			if (err.response.data.errormsg === 'User Not Found') {
				throw new Error('User Not Found');
			} else {
				throw new Error('Error getting user from email');
			}
		});
};

export const getEmailFromId = async (ID) => {
	return await Axios.post(SERVER_URL + 'getEmailFromId', {
		user_id: ID,
	})
		.then((response) => {
			return response.data.data.userEmail;
		})
		.catch((err) => {
			//
			if (err.response.data.error === "Error: Could Not Get ID") {
				throw new Error("Error: Could Not Get ID");
			} else {
				throw new Error('Error getting ID from email');
			}
		});
};

